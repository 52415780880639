import moment, { Moment } from 'moment-timezone';
import { z } from 'zod';

export const splitPracticeDetails = (practice: string): { name: string; odsCode: string } => {
    const slicePoint = practice.lastIndexOf(' ');
    const name = practice.slice(0, slicePoint);
    const odsCode = practice.slice(slicePoint + 1);
    return { name, odsCode };
};

// This will accepts dates and also strings and numbers that can be parsed as dates
const dateSchema = z.coerce.date();

/**
 * Returns a tuple of ISO strings representing the start and end of the day of the given date
 * @example
 * getStartAndEndDay(new Date('2021-10-10T10:00:00.000Z')) // ['2021-10-10T00:00:00.000Z', '2021-10-10T23:59:59.999Z']
 * getStartAndEndDay('2021-10-10T10:00:00.000Z') // ['2021-10-10T00:00:00.000Z', '2021-10-10T23:59:59.999Z']
 * getStartAndEndDay(1633830000000) // ['2021-10-10T00:00:00.000Z', '2021-10-10T23:59:59.999Z']
 */
export const getStartAndEndDay = (date: Date | string | number): [string, string] => {
    const dateParseAttempt = dateSchema.safeParse(date);
    if (!dateParseAttempt.success) {
        throw new Error('Invalid date format');
    }
    const dateParsed = dateParseAttempt.data;
    const start = moment(dateParsed).startOf('day').toISOString();
    const end = moment(dateParsed).endOf('day').toISOString();

    return [start, end];
};

export const getMiddayDayString = ({ date }: { date: Moment }) => {
    return moment(date.startOf('day').hour(12)).toISOString();
};
